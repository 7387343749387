<template>
	<modal class="NoxModalAPI" name="NoxModalAPI" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_head">
				<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
				<div class="nox_modal_title">
					<span v-if="noxType == 'addAPIEndpoint'">Добавление эндпоинта</span>
					<span v-else-if="noxType == 'editAPIEndpoint'">Редактирование эндпоинта</span>
					<span v-else-if="noxType == 'deleteAPIEndpoint'">Удаление эндпоинта</span>
					<span v-else-if="noxType == 'addAPIParameter'">Добавление параметра</span>
					<span v-else-if="noxType == 'editAPIParameter'">Редактирование параметра</span>
					<span v-else-if="noxType == 'deleteAPIParameter'">Удаление параметра</span>
					<span v-else-if="noxType == 'addAPIResponse'">Добавление ответа</span>
					<span v-else-if="noxType == 'editAPIResponse'">Редактирование ответа</span>
					<span v-else-if="noxType == 'deleteAPIResponse'">Удаление ответа</span>
				</div>
			</div>
			<div class="nox_modal_body">
				<div class="nox_modal_info">
					<div v-if="['addAPIEndpoint', 'editAPIEndpoint'].includes(noxType)">
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Категория NOX API:</div>
							<div class="nox_modal_info_row_value">
								<div class="nox_modal_info_row_value_text">{{ ($parent.noxAPICategories[$parent.noxAPICategories.findIndex(i => i.code == noxAPICategoryId)].label) }}</div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Подкатегория NOX API:</div>
							<div class="nox_modal_info_row_value">
								<v-select class="nox_select style-chooser" v-model.trim="noxAPISubcategory" placeholder="выберите подкатегорию nox api" :options="$parent.noxAPISubcategories"></v-select>
								<div v-html="noxAlertAPIEndpointSubcategory"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Метод эндпоинта:</div>
							<div class="nox_modal_info_row_value">
								<v-select class="nox_select style-chooser" v-model.trim="noxAPIEndpointMethod" placeholder="выберите метод эндпоинта" :options="$parent.noxAPIEndpointMethods"></v-select>
								<div v-html="noxAlertAPIEndpointMethod"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Путь эндпоинта:</div>
							<div class="nox_modal_info_row_value">
								<input type="text" class="nox_input" v-model.trim="noxAPIEndpointPath" placeholder="укажите путь эндпоинта" autocomplete="off">
								<div v-html="noxAlertAPIEndpointPath"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Описание эндпоинта:</div>
							<div class="nox_modal_info_row_value">
								<textarea class="nox_textarea small" v-model.trim="noxAPIEndpointDescription" placeholder="укажите описание эндпоинта"></textarea>
								<div v-html="noxAlertAPIEndpointDescription"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Постоянный токен:</div>
							<div class="nox_modal_info_row_value">
								<toggle-button class="nox_toggle_button" :value="toggleValue(noxAPIEndpointIsNOXToken)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange($event, 1)"></toggle-button>
								<div v-html="noxAlertAPIEndpointIsNOXToken"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Начало новой группы:</div>
							<div class="nox_modal_info_row_value">
								<toggle-button class="nox_toggle_button" :value="toggleValue(noxAPIEndpointIsNewGroup)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange($event, 2)"></toggle-button>
								<div v-html="noxAlertAPIEndpointIsNewGroup"></div>
							</div>
						</div>
						<div class="nox_modal_info_row last">
							<div class="nox_modal_info_row_label">Показывать эндпоинт:</div>
							<div class="nox_modal_info_row_value">
								<toggle-button class="nox_toggle_button" :value="toggleValue(noxAPIEndpointIsShow)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange($event, 3)"></toggle-button>
								<div v-html="noxAlertAPIEndpointIsShow"></div>
							</div>
						</div>
					</div>
					<div v-else-if="noxType == 'deleteAPIEndpoint'">Вы действительно хотите удалить эндпоинт?</div>
					<div v-else-if="['addAPIParameter', 'editAPIParameter'].includes(noxType)">
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Название параметра:</div>
							<div class="nox_modal_info_row_value">
								<input type="text" class="nox_input" v-model.trim="noxAPIParameterName" placeholder="укажите название параметра" autocomplete="off">
								<div v-html="noxAlertAPIParameterName"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Тип параметра:</div>
							<div class="nox_modal_info_row_value">
								<v-select class="nox_select style-chooser" v-model.trim="noxAPIParameterType" placeholder="выберите тип параметра" :options="$parent.noxAPIParameterTypes"></v-select>
								<div v-html="noxAlertAPIParameterType"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">RegExp параметра:</div>
							<div class="nox_modal_info_row_value">
								<v-select class="nox_select style-chooser" v-model.trim="noxAPIParameterRegExp" placeholder="выберите regexp параметра" :options="$parent.noxAPIParameterRegExps"></v-select>
								<div v-html="noxAlertAPIParameterRegExp"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Пример параметра:</div>
							<div class="nox_modal_info_row_value">
								<input type="text" class="nox_input" v-model.trim="noxAPIParameterExample" placeholder="укажите пример параметра" autocomplete="off">
								<div v-html="noxAlertAPIParameterExample"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Описание параметра:</div>
							<div class="nox_modal_info_row_value">
								<textarea class="nox_textarea small" v-model.trim="noxAPIParameterDescription" placeholder="укажите описание параметра"></textarea>
								<div v-html="noxAlertAPIParameterDescription"></div>
							</div>
						</div>
						<div class="nox_modal_info_row last">
							<div class="nox_modal_info_row_label">Обязательный парам.:</div>
							<div class="nox_modal_info_row_value">
								<toggle-button class="nox_toggle_button" :value="toggleValue(noxAPIParameterIsRequired)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange($event, 4)"></toggle-button>
								<div v-html="noxAlertAPIParameterIsRequired"></div>
							</div>
						</div>
					</div>
					<div v-else-if="noxType == 'deleteAPIParameter'">Вы действительно хотите удалить параметр?</div>
					<div v-else-if="['addAPIResponse', 'editAPIResponse'].includes(noxType)">
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Код ответа:</div>
							<div class="nox_modal_info_row_value">
								<v-select class="nox_select style-chooser" v-model.trim="noxAPIResponseCode" placeholder="выберите код ответа" :options="$parent.noxAPIResponseCodes"></v-select>
								<div v-html="noxAlertAPIResponseCode"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Тип ответа:</div>
							<div class="nox_modal_info_row_value">
								<v-select class="nox_select style-chooser" v-model.trim="noxAPIResponseType" placeholder="выберите тип ответа" :options="$parent.noxAPIResponseTypes"></v-select>
								<div v-html="noxAlertAPIResponseType"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Данные ответа:</div>
							<div class="nox_modal_info_row_value">
								<textarea class="nox_textarea small" v-model.trim="noxAPIResponseData" placeholder="укажите данные ответа"></textarea>
								<div v-html="noxAlertAPIResponseData"></div>
							</div>
						</div>
						<div class="nox_modal_info_row last">
							<div class="nox_modal_info_row_label">Описание ответа:</div>
							<div class="nox_modal_info_row_value">
								<textarea class="nox_textarea small" v-model.trim="noxAPIResponseDescription" placeholder="укажите описание ответа"></textarea>
								<div v-html="noxAlertAPIResponseDescription"></div>
							</div>
						</div>
					</div>
					<div v-else-if="noxType == 'deleteAPIResponse'">Вы действительно хотите удалить ответ?</div>
				</div>
				<div class="nox_modal_alert" v-html="noxAlert"></div>
				<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
				<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
					<button type="button" class="nox_button common" @click="close" v-html="$store.getters.getLanguageText('1.1', 3)"></button>
					<button type="button" class="nox_button common green" @click="axios" v-if="['addAPIEndpoint', 'addAPIParameter', 'addAPIResponse'].includes(noxType)" v-html="$store.getters.getLanguageText('1.1', 5)"></button>
					<button type="button" class="nox_button common green" @click="axios" v-else-if="['editAPIEndpoint', 'editAPIParameter', 'editAPIResponse'].includes(noxType)" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
					<button type="button" class="nox_button common red" @click="axios" v-else-if="['deleteAPIEndpoint', 'deleteAPIParameter', 'deleteAPIResponse'].includes(noxType)" v-html="$store.getters.getLanguageText('1.1', 11)"></button>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxData: {},
			noxAlert: '',
			noxAlertAPIEndpointSubcategory: '',
			noxAlertAPIEndpointMethod: '',
			noxAlertAPIEndpointPath: '',
			noxAlertAPIEndpointDescription: '',
			noxAlertAPIEndpointIsNOXToken: '',
			noxAlertAPIEndpointIsNewGroup: '',
			noxAlertAPIEndpointIsShow: '',
			noxAlertAPIParameterName: '',
			noxAlertAPIParameterType: '',
			noxAlertAPIParameterRegExp: '',
			noxAlertAPIParameterExample: '',
			noxAlertAPIParameterDescription: '',
			noxAlertAPIParameterIsRequired: '',
			noxAlertAPIResponseCode: '',
			noxAlertAPIResponseType: '',
			noxAlertAPIResponseData: '',
			noxAlertAPIResponseDescription: '',
			noxType: '',
			noxAPICategoryId: 0,
			noxAPISubcategory: '',
			noxAPISubcategoryId: 0,
			noxAPISubcategoryIndex: 0,
			noxAPIEndpointId: 0,
			noxAPIEndpointMethod: '',
			noxAPIEndpointPath: '',
			noxAPIEndpointDescription: '',
			noxAPIEndpointIsNOXToken: 0,
			noxAPIEndpointIsNewGroup: 0,
			noxAPIEndpointIsShow: 0,
			noxAPIParameterId: 0,
			noxAPIParameterIndex: 0,
			noxAPIParameterName: '',
			noxAPIParameterType: '',
			noxAPIParameterRegExp: 0,
			noxAPIParameterExample: '',
			noxAPIParameterDescription: '',
			noxAPIParameterIsRequired: 0,
			noxAPIResponseId: 0,
			noxAPIResponseIndex: 0,
			noxAPIResponseCode: '',
			noxAPIResponseType: '',
			noxAPIResponseData: '',
			noxAPIResponseDescription: '',
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.noxData = {};
				this.resetAlerts();
				this.noxType = event.params.type;
				this.noxAPICategoryId = event.params.category_id;
				this.noxAPISubcategory = '';
				this.noxAPISubcategoryId = event.params.subcategory_id;
				this.noxAPISubcategoryIndex = 0;
				this.noxAPIEndpointId = event.params.endpoint_id;
				this.noxAPIEndpointMethod = '';
				this.noxAPIEndpointPath = '';
				this.noxAPIEndpointDescription = '';
				this.noxAPIEndpointIsNOXToken = 0;
				this.noxAPIEndpointIsNewGroup = 0;
				this.noxAPIEndpointIsShow = 0;
				this.noxAPIParameterId = event.params.parameter_id;
				this.noxAPIParameterIndex = event.params.parameter_index;
				this.noxAPIParameterName = '';
				this.noxAPIParameterType = '';
				this.noxAPIParameterRegExp = 0;
				this.noxAPIParameterExample = '';
				this.noxAPIParameterDescription = '';
				this.noxAPIParameterIsRequired = 0;
				this.noxAPIResponseId = event.params.response_id;
				this.noxAPIResponseIndex = event.params.response_index;
				this.noxAPIResponseCode = '';
				this.noxAPIResponseType = '';
				this.noxAPIResponseData = '';
				this.noxAPIResponseDescription = '';
				this.noxIsLoading = false;
				this.noxIsError = false;

				if (this.noxType == 'editAPIEndpoint') {

					this.noxData = this.$parent.noxAPIData;
					this.noxData = this.noxData.categories[this.noxAPICategoryId];
					this.noxData = this.noxData.subcategories[this.noxAPISubcategoryId];
					this.noxData = this.noxData.endpoints['id' + this.noxAPIEndpointId];
					this.noxAPISubcategoryIndex = this.$parent.noxAPISubcategories.findIndex(i => i.code == this.noxAPISubcategoryId);
					this.noxAPISubcategory = this.$parent.noxAPISubcategories[this.noxAPISubcategoryIndex];
					this.noxAPIEndpointMethod = this.noxData.method;
					this.noxAPIEndpointPath = this.noxData.path;
					this.noxAPIEndpointDescription = this.noxData.description;
					this.noxAPIEndpointIsNOXToken = this.noxData.is_nox_token;
					this.noxAPIEndpointIsNewGroup = this.noxData.is_new_group;
					this.noxAPIEndpointIsShow = this.noxData.is_show;
				}
				else if (this.noxType == 'editAPIParameter') {

					this.noxData = this.$parent.noxAPIData;
					this.noxData = this.noxData.categories[this.noxAPICategoryId];
					this.noxData = this.noxData.subcategories[this.noxAPISubcategoryId];
					this.noxData = this.noxData.endpoints['id' + this.noxAPIEndpointId];
					this.noxData = this.noxData.parameters[this.noxAPIParameterIndex];
					this.noxAPIParameterName = this.noxData.name;
					this.noxAPIParameterType = this.noxData.type;
					this.noxAPIParameterRegExp = this.$parent.noxAPIParameterRegExps[this.noxData.regexp];
					this.noxAPIParameterExample = this.noxData.example;
					this.noxAPIParameterDescription = this.noxData.description;
					this.noxAPIParameterIsRequired = this.noxData.is_required;
				}
				else if (this.noxType == 'editAPIResponse') {

					this.noxData = this.$parent.noxAPIData;
					this.noxData = this.noxData.categories[this.noxAPICategoryId];
					this.noxData = this.noxData.subcategories[this.noxAPISubcategoryId];
					this.noxData = this.noxData.endpoints['id' + this.noxAPIEndpointId];
					this.noxData = this.noxData.responses[this.noxAPIResponseIndex];
					this.noxAPIResponseCode = this.noxData.code;
					this.noxAPIResponseType = this.noxData.type;
					this.noxAPIResponseData = (this.noxData.data ? (['array', 'object'].includes(this.noxData.type) ? JSON.stringify(this.noxData.data) : this.noxData.data) : '');
					this.noxAPIResponseDescription = this.noxData.description;
				}
			},
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertAPIEndpointSubcategory = '';
				this.noxAlertAPIEndpointMethod = '';
				this.noxAlertAPIEndpointPath = '';
				this.noxAlertAPIEndpointDescription = '';
				this.noxAlertAPIEndpointIsNOXToken = '';
				this.noxAlertAPIEndpointIsNewGroup = '';
				this.noxAlertAPIEndpointIsShow = '';
				this.noxAlertAPIParameterName = '';
				this.noxAlertAPIParameterType = '';
				this.noxAlertAPIParameterRegExp = '';
				this.noxAlertAPIParameterExample = '';
				this.noxAlertAPIParameterDescription = '';
				this.noxAlertAPIParameterIsRequired = '';
				this.noxAlertAPIResponseCode = '';
				this.noxAlertAPIResponseType = '';
				this.noxAlertAPIResponseData = '';
				this.noxAlertAPIResponseDescription = '';
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = 'Необходимо указать id категории.'; }
				else if (i == 2) { this.noxTemp = 'Необходимо указать верный id категории.'; }
				else if (i == 3) { this.noxTemp = 'Необходимо указать id подкатегории.'; }
				else if (i == 4) { this.noxTemp = 'Необходимо указать верный id подкатегории.'; }
				else if (i == 5) { this.noxTemp = 'Необходимо указать id эндпоинта.'; }
				else if (i == 6) { this.noxTemp = 'Необходимо указать верный id эндпоинта.'; }
				else if (i == 7) { this.noxTemp = 'Необходимо указать id параметра.'; }
				else if (i == 8) { this.noxTemp = 'Необходимо указать верный id параметра.'; }
				else if (i == 9) { this.noxTemp = 'Необходимо указать id ответа.'; }
				else if (i == 10) { this.noxTemp = 'Необходимо указать верный id ответа.'; }
				else if (i == 11) { this.noxTemp = 'Необходимо выбрать подкатегорию эндпоинта.'; }
				else if (i == 12) { this.noxTemp = 'Необходимо выбрать верную подкатегорию эндпоинта.'; }
				else if (i == 13) { this.noxTemp = 'Необходимо выбрать метод эндпоинта.'; }
				else if (i == 14) { this.noxTemp = 'Необходимо выбрать верный метод эндпоинта.'; }
				else if (i == 15) { this.noxTemp = 'Необходимо указать путь эндпоинта.'; }
				else if (i == 16) { this.noxTemp = 'Необходимо указать верный путь эндпоинта.'; }
				else if (i == 17) { this.noxTemp = 'Необходимо указать описание эндпоинта.'; }
				else if (i == 18) { this.noxTemp = 'Необходимо указать верное описание эндпоинта. Разрешено вводить буквы, цифры и некоторые знаки препинания. Длина: до 500 символов.'; }
				else if (i == 19) { this.noxTemp = 'Необходимо указать флаг постоянного токена.'; }
				else if (i == 20) { this.noxTemp = 'Необходимо указать верный флаг постоянного токена.'; }
				else if (i == 21) { this.noxTemp = 'Необходимо указать флаг начала новой группы.'; }
				else if (i == 22) { this.noxTemp = 'Необходимо указать верный флаг начала новой группы.'; }
				else if (i == 23) { this.noxTemp = 'Необходимо указать флаг показа эндпоинта.'; }
				else if (i == 24) { this.noxTemp = 'Необходимо указать верный флаг показа эндпоинта.'; }
				else if (i == 25) { this.noxTemp = 'Необходимо указать название параметра.'; }
				else if (i == 26) { this.noxTemp = 'Необходимо указать верное название параметра. Разрешено вводить латинские буквы, цифры и знак подчеркивания. Длина: до 100 символов.'; }
				else if (i == 27) { this.noxTemp = 'Необходимо выбрать тип параметра.'; }
				else if (i == 28) { this.noxTemp = 'Необходимо выбрать верный тип параметра.'; }
				else if (i == 29) { this.noxTemp = 'Необходимо выбрать regexp параметра.'; }
				else if (i == 30) { this.noxTemp = 'Необходимо выбрать верный regexp параметра.'; }
				else if (i == 31) { this.noxTemp = 'Необходимо указать пример параметра.'; }
				else if (i == 32) { this.noxTemp = 'Необходимо указать верный пример параметра. Разрешено вводить буквы, цифры и некоторые знаки препинания. Длина: до 200 символов.'; }
				else if (i == 33) { this.noxTemp = 'Необходимо указать описание параметра.'; }
				else if (i == 34) { this.noxTemp = 'Необходимо указать верное описание параметра. Разрешено вводить буквы, цифры и некоторые знаки препинания. Длина: до 500 символов.'; }
				else if (i == 35) { this.noxTemp = 'Необходимо указать флаг требования параметра.'; }
				else if (i == 36) { this.noxTemp = 'Необходимо указать верный флаг требования параметра.'; }
				else if (i == 37) { this.noxTemp = 'Необходимо выбрать код ответа.'; }
				else if (i == 38) { this.noxTemp = 'Необходимо выбрать верный код ответа.'; }
				else if (i == 39) { this.noxTemp = 'Необходимо выбрать тип ответа.'; }
				else if (i == 40) { this.noxTemp = 'Необходимо выбрать верный тип ответа.'; }
				else if (i == 41) { this.noxTemp = 'Необходимо указать данные ответа.'; }
				else if (i == 42) { this.noxTemp = 'Необходимо указать верные данные ответа.'; }
				else if (i == 43) { this.noxTemp = 'Необходимо указать описание ответа.'; }
				else if (i == 44) { this.noxTemp = 'Необходимо указать верное описание ответа. Разрешено вводить буквы, цифры и некоторые знаки препинания. Длина: до 500 символов.'; }
				else if (i == 45) { this.noxTemp = 'Id категории не найден в системе.'; }
				else if (i == 46) { this.noxTemp = 'Id подкатегории не найден в системе.'; }
				else if (i == 47) { this.noxTemp = 'Id эндпоинта не найден в системе.'; }
				else if (i == 48) { this.noxTemp = 'Id параметра не найден в системе.'; }
				else if (i == 49) { this.noxTemp = 'Id ответа не найден в системе.'; }
				else              { this.noxTemp = 'Внутренняя ошибка сервера.'; }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			axios: function() {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (['addAPIEndpoint', 'editAPIEndpoint'].includes(_this.noxType)) {
						if (!_this.noxAPISubcategory) {
							_this.noxAlertAPIEndpointSubcategory = _this.getError(11);
						}
						else if (_this.noxAPISubcategory.code == undefined) {
							_this.noxAlertAPIEndpointSubcategory = _this.getError(12);
						}
						if (!_this.noxAPIEndpointMethod) {
							_this.noxAlertAPIEndpointMethod = _this.getError(13);
						}
						else if (!_this.$parent.noxAPIEndpointMethods.includes(_this.noxAPIEndpointMethod)) {
							_this.noxAlertAPIEndpointMethod = _this.getError(14);
						}
						if (!_this.noxAPIEndpointPath) {
							_this.noxAlertAPIEndpointPath = _this.getError(15);
						}
						else if (!_this.$store.state.noxRegexAPIEndpointsPath.test(_this.noxAPIEndpointPath) || _this.noxAPIEndpointPath.length > 100) {
							_this.noxAlertAPIEndpointPath = _this.getError(16);
						}
						if (_this.noxAPIEndpointDescription && (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxAPIEndpointDescription) || _this.noxAPIEndpointDescription.length > 500)) {
							_this.noxAlertAPIEndpointDescription = _this.getError(18);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/admin/account/api/endpoints';
							config.data = { category_id: _this.noxAPICategoryId, subcategory_id: _this.noxAPISubcategory.code, endpoint_id: _this.noxAPIEndpointId, method: _this.noxAPIEndpointMethod, path: _this.noxAPIEndpointPath, description: _this.noxAPIEndpointDescription, is_nox_token: _this.noxAPIEndpointIsNOXToken, is_new_group: _this.noxAPIEndpointIsNewGroup, is_show: _this.noxAPIEndpointIsShow };
							config.method = _this.noxType == 'addAPIEndpoint' ? 'post' : 'patch';
						}
					}
					else if (_this.noxType == 'deleteAPIEndpoint') {
						config.url = '/v2/admin/account/api/endpoints';
						config.data = { category_id: _this.noxAPICategoryId, subcategory_id: _this.noxAPISubcategory.code, endpoint_id: _this.noxAPIEndpointId };
						config.method = 'delete';
					}
					else if (['addAPIParameter', 'editAPIParameter'].includes(_this.noxType)) {
						if (!_this.noxAPIParameterName) {
							_this.noxAlertAPIParameterName = _this.getError(25);
						}
						else if (!_this.$store.state.noxRegex_w.test(_this.noxAPIParameterName) || _this.noxAPIParameterName.length > 100) {
							_this.noxAlertAPIParameterName = _this.getError(26);
						}
						if (!_this.noxAPIParameterType) {
							_this.noxAlertAPIParameterType = _this.getError(27);
						}
						else if (!_this.$parent.noxAPIParameterTypes.includes(_this.noxAPIParameterType)) {
							_this.noxAlertAPIParameterType = _this.getError(28);
						}
						if (!_this.noxAPIParameterRegExp) {
							_this.noxAlertAPIParameterRegExp = _this.getError(29);
						}
						else if (_this.noxAPIParameterRegExp.code == undefined) {
							_this.noxAlertAPIParameterRegExp = _this.getError(30);
						}
						if (String(_this.noxAPIParameterExample) == '') {
							_this.noxAlertAPIParameterExample = _this.getError(31);
						}
						else if (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxAPIParameterExample) || _this.noxAPIParameterExample.length > 200) {
							_this.noxAlertAPIParameterExample = _this.getError(32);
						}
						if (_this.noxAPIParameterDescription && (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxAPIParameterDescription) || _this.noxAPIParameterDescription.length > 500)) {
							_this.noxAlertAPIParameterDescription = _this.getError(34);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/admin/account/api/parameters';
							config.data = { category_id: _this.noxAPICategoryId, subcategory_id: _this.noxAPISubcategoryId, endpoint_id: _this.noxAPIEndpointId, parameter_id: _this.noxAPIParameterId, name: _this.noxAPIParameterName, type: _this.noxAPIParameterType, regexp: _this.noxAPIParameterRegExp.code, example: _this.noxAPIParameterExample, description: _this.noxAPIParameterDescription, is_required: _this.noxAPIParameterIsRequired };
							config.method = _this.noxType == 'addAPIParameter' ? 'post' : 'patch';
						}
					}
					else if (_this.noxType == 'deleteAPIParameter') {
						config.url = '/v2/admin/account/api/parameters';
						config.data = { category_id: _this.noxAPICategoryId, subcategory_id: _this.noxAPISubcategoryId, endpoint_id: _this.noxAPIEndpointId, parameter_id: _this.noxAPIParameterId };
						config.method = 'delete';
					}
					else if (['addAPIResponse', 'editAPIResponse'].includes(_this.noxType)) {
						if (!_this.noxAPIResponseCode) {
							_this.noxAlertAPIResponseCode = _this.getError(37);
						}
						else if (!_this.$parent.noxAPIResponseCodes.includes(_this.noxAPIResponseCode)) {
							_this.noxAlertAPIResponseCode = _this.getError(38);
						}
						if (!_this.noxAPIResponseType) {
							_this.noxAlertAPIResponseType = _this.getError(39);
						}
						else if (!_this.$parent.noxAPIResponseTypes.includes(_this.noxAPIResponseType)) {
							_this.noxAlertAPIResponseType = _this.getError(40);
						}
						if (['array', 'object'].includes(_this.noxAPIResponseType) && !_this.noxAPIResponseData) {
							_this.noxAlertAPIResponseData = _this.getError(41);
						}
						else if (['array', 'object'].includes(_this.noxAPIResponseType) && _this.noxAPIResponseData) {
							if (!_this.$parent.$parent.$parent.isJSONParse(_this.noxAPIResponseData)) {
								_this.noxAlertAPIResponseData = _this.getError(42);
							}
							else if (_this.noxAPIResponseType == 'array' && !Array.isArray(JSON.parse(_this.noxAPIResponseData))) {
								_this.noxAlertAPIResponseData = _this.getError(42);
							}
						}
						else if (_this.noxAPIResponseType == 'string' && (typeof(_this.noxAPIResponseData) !== 'string')) {
							_this.noxAlertAPIResponseData = _this.getError(42);
						}
						if (_this.noxAPIResponseDescription && (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxAPIResponseDescription) || _this.noxAPIResponseDescription.length > 500)) {
							_this.noxAlertAPIResponseDescription = _this.getError(44);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/admin/account/api/responses';
							config.data = { category_id: _this.noxAPICategoryId, subcategory_id: _this.noxAPISubcategoryId, endpoint_id: _this.noxAPIEndpointId, response_id: _this.noxAPIResponseId, code: _this.noxAPIResponseCode, type: _this.noxAPIResponseType, data: _this.noxAPIResponseData, description: _this.noxAPIResponseDescription };
							config.method = _this.noxType == 'addAPIResponse' ? 'post' : 'patch';
						}
					}
					else if (_this.noxType == 'deleteAPIResponse') {
						config.url = '/v2/admin/account/api/responses';
						config.data = { category_id: _this.noxAPICategoryId, subcategory_id: _this.noxAPISubcategoryId, endpoint_id: _this.noxAPIEndpointId, response_id: _this.noxAPIResponseId };
						config.method = 'delete';
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (['addAPIEndpoint', 'editAPIEndpoint', 'deleteAPIEndpoint', 'addAPIParameter', 'editAPIParameter', 'deleteAPIParameter', 'addAPIResponse', 'editAPIResponse', 'deleteAPIResponse'].includes(_this.noxType)) {
									_this.$parent.initAPIData(data.data);
								}
								_this.close();
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (['addAPIEndpoint', 'editAPIEndpoint', 'deleteAPIEndpoint', 'addAPIParameter', 'editAPIParameter', 'deleteAPIParameter', 'addAPIResponse', 'editAPIResponse', 'deleteAPIResponse'].includes(_this.noxType)) {
										if      (data.response.data.error == 'CATEGORY_ID_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
										else if (data.response.data.error == 'CATEGORY_ID_NOT_VALID') { _this.noxAlert = _this.getError(2); }
										else if (data.response.data.error == 'SUBCATEGORY_ID_IS_EMPTY') { _this.noxAlert = _this.getError(3); }
										else if (data.response.data.error == 'SUBCATEGORY_ID_NOT_VALID') { _this.noxAlert = _this.getError(4); }
										else if (data.response.data.error == 'ENDPOINT_ID_IS_EMPTY') { _this.noxAlert = _this.getError(5); }
										else if (data.response.data.error == 'ENDPOINT_ID_NOT_VALID') { _this.noxAlert = _this.getError(6); }
										else if (data.response.data.error == 'PARAMETER_ID_IS_EMPTY') { _this.noxAlert = _this.getError(7); }
										else if (data.response.data.error == 'PARAMETER_ID_NOT_VALID') { _this.noxAlert = _this.getError(8); }
										else if (data.response.data.error == 'RESPONSE_ID_IS_EMPTY') { _this.noxAlert = _this.getError(9); }
										else if (data.response.data.error == 'RESPONSE_ID_NOT_VALID') { _this.noxAlert = _this.getError(10); }
										if (['addAPIEndpoint', 'editAPIEndpoint', 'deleteAPIEndpoint'].includes(_this.noxType)) {
											if      (data.response.data.error == 'METHOD_IS_EMPTY') { _this.noxAlertAPIEndpointMethod = _this.getError(13); }
											else if (data.response.data.error == 'METHOD_NOT_VALID') { _this.noxAlertAPIEndpointMethod = _this.getError(14); }
											else if (data.response.data.error == 'PATH_IS_EMPTY') { _this.noxAlertAPIEndpointPath = _this.getError(15); }
											else if (data.response.data.error == 'PATH_NOT_VALID') { _this.noxAlertAPIEndpointPath = _this.getError(16); }
											else if (data.response.data.error == 'DESCRIPTION_IS_EMPTY') { _this.noxAlertAPIEndpointDescription = _this.getError(17); }
											else if (data.response.data.error == 'DESCRIPTION_NOT_VALID') { _this.noxAlertAPIEndpointDescription = _this.getError(18); }
											else if (data.response.data.error == 'IS_NOX_TOKEN_IS_EMPTY') { _this.noxAlertAPIEndpointIsNOXToken = _this.getError(19); }
											else if (data.response.data.error == 'IS_NOX_TOKEN_NOT_VALID') { _this.noxAlertAPIEndpointIsNOXToken = _this.getError(20); }
											else if (data.response.data.error == 'IS_NEW_GROUP_IS_EMPTY') { _this.noxAlertAPIEndpointIsNewGroup = _this.getError(21); }
											else if (data.response.data.error == 'IS_NEW_GROUP_NOT_VALID') { _this.noxAlertAPIEndpointIsNewGroup = _this.getError(22); }
											else if (data.response.data.error == 'IS_SHOW_IS_EMPTY') { _this.noxAlertAPIEndpointIsShow = _this.getError(23); }
											else if (data.response.data.error == 'IS_SHOW_NOT_VALID') { _this.noxAlertAPIEndpointIsShow = _this.getError(24); }
										}
										else if (['addAPIParameter', 'editAPIParameter', 'deleteAPIParameter'].includes(_this.noxType)) {
											if      (data.response.data.error == 'NAME_IS_EMPTY') { _this.noxAlertAPIParameterName = _this.getError(25); }
											else if (data.response.data.error == 'NAME_NOT_VALID') { _this.noxAlertAPIParameterName = _this.getError(26); }
											else if (data.response.data.error == 'TYPE_IS_EMPTY') { _this.noxAlertAPIParameterType = _this.getError(27); }
											else if (data.response.data.error == 'TYPE_NOT_VALID') { _this.noxAlertAPIParameterType = _this.getError(28); }
											else if (data.response.data.error == 'REGEXP_IS_EMPTY') { _this.noxAlertAPIParameterRegExp = _this.getError(29); }
											else if (data.response.data.error == 'REGEXP_NOT_VALID') { _this.noxAlertAPIParameterRegExp = _this.getError(30); }
											else if (data.response.data.error == 'EXAMPLE_IS_EMPTY') { _this.noxAlertAPIParameterExample = _this.getError(31); }
											else if (data.response.data.error == 'EXAMPLE_NOT_VALID') { _this.noxAlertAPIParameterExample = _this.getError(32); }
											else if (data.response.data.error == 'DESCRIPTION_IS_EMPTY') { _this.noxAlertAPIParameterDescription = _this.getError(33); }
											else if (data.response.data.error == 'DESCRIPTION_NOT_VALID') { _this.noxAlertAPIParameterDescription = _this.getError(34); }
											else if (data.response.data.error == 'IS_REQUIRED_IS_EMPTY') { _this.noxAlertAPIParameterIsRequired = _this.getError(35); }
											else if (data.response.data.error == 'IS_REQUIRED_NOT_VALID') { _this.noxAlertAPIParameterIsRequired = _this.getError(36); }
										}
										else if (['addAPIResponse', 'editAPIResponse', 'deleteAPIResponse'].includes(_this.noxType)) {
											if      (data.response.data.error == 'CODE_IS_EMPTY') { _this.noxAlertAPIResponseCode = _this.getError(37); }
											else if (data.response.data.error == 'CODE_NOT_VALID') { _this.noxAlertAPIResponseCode = _this.getError(38); }
											else if (data.response.data.error == 'TYPE_IS_EMPTY') { _this.noxAlertAPIResponseType = _this.getError(39); }
											else if (data.response.data.error == 'TYPE_NOT_VALID') { _this.noxAlertAPIResponseType = _this.getError(40); }
											else if (data.response.data.error == 'DATA_IS_EMPTY') { _this.noxAlertAPIResponseData = _this.getError(41); }
											else if (data.response.data.error == 'DATA_NOT_VALID') { _this.noxAlertAPIResponseData = _this.getError(42); }
											else if (data.response.data.error == 'DESCRIPTION_IS_EMPTY') { _this.noxAlertAPIResponseDescription = _this.getError(43); }
											else if (data.response.data.error == 'DESCRIPTION_NOT_VALID') { _this.noxAlertAPIResponseDescription = _this.getError(44); }
										}
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (['addAPIEndpoint', 'editAPIEndpoint', 'deleteAPIEndpoint', 'addAPIParameter', 'editAPIParameter', 'deleteAPIParameter', 'addAPIResponse', 'editAPIResponse', 'deleteAPIResponse'].includes(_this.noxType)) {
										if      (data.response.data.error == 'CATEGORY_ID_NOT_FOUND') { _this.noxAlert = _this.getError(45); }
										else if (data.response.data.error == 'SUBCATEGORY_ID_NOT_FOUND') { _this.noxAlert = _this.getError(46); }
										else if (data.response.data.error == 'ENDPOINT_ID_NOT_FOUND') { _this.noxAlert = _this.getError(47); }
										else if (data.response.data.error == 'PARAMETER_ID_NOT_FOUND') { _this.noxAlert = _this.getError(48); }
										else if (data.response.data.error == 'RESPONSE_ID_NOT_FOUND') { _this.noxAlert = _this.getError(49); }
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			toggleValue: function(value) {
				return value > 0 ? true : false;
			},
			toggleChange: function(event, type) {
				if      (type == 1) { this.noxAPIEndpointIsNOXToken = Number(event.value); }
				else if (type == 2) { this.noxAPIEndpointIsNewGroup = Number(event.value); }
				else if (type == 3) { this.noxAPIEndpointIsShow = Number(event.value); }
				else if (type == 4) { this.noxAPIParameterIsRequired = Number(event.value); }
			},
			close: function() {
				this.$modal.hide('NoxModalAPI');
			}
		}
	}
</script>
